import styles from "./Toggle.module.scss";
import {ReactElement, useEffect, useRef, useState, useId} from "react";
import classNames from "classnames";

export function Toggle(props: {
    value: string,
    onChange: (value: string) => void,
    options: Array<{ label: string, value: string, icon?: ReactElement}>
    className?: string,
    [prop: string]: any,
}) {
    const {value, onChange, options, ...validHtmlAttributes} = props;
    const toggleRef = useRef<null | HTMLDivElement>(null);
    const [highlightWidth, setHighlightWidth] = useState(0);
    const [highlightLeft, setHighlightLeft] = useState(0);

    useEffect(() => {
        if (toggleRef.current) {
            const selectedLabel: HTMLLabelElement | null = toggleRef.current.querySelector(`input[value=${CSS.escape(props.value)}] + label`);
            if (selectedLabel) {
                const selectedLabelRect = selectedLabel.getBoundingClientRect();
                setHighlightWidth(selectedLabelRect.width);
                setHighlightLeft(selectedLabel.offsetLeft);
            }
        }
    }, [props.value, props.options]);

    const toggleClassNames = classNames({
        [styles.toggle]: true,
        [props.className as string]: props.className,
    })
    const id = useId();

    return (
      <div
        {...validHtmlAttributes}
        className={toggleClassNames}
        ref={toggleRef}
      >
        {props.options.length > 1 && props.options.map(opt => (
          <span key={opt.value}>
            <input
              name={`${opt.value}-${id}`}
              id={`${opt.value}-${id}`}
              type={"radio"}
              value={opt.value}
              checked={props.value === opt.value}
              onChange={e => props.onChange(e.target.value)}
            />
            <label title={opt.label} htmlFor={`${opt.value}-${id}`}>
              <span>
                {opt.icon} {opt.label}
              </span>
            </label>
          </span>
        ))}
        <div style={{ width: `${highlightWidth}px`, left: highlightLeft }} />
      </div>
    );
}
